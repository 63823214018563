import React, {useState} from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Page from "../../components/atoms/page";
import wood from "../../images/deals/wood.jpg";
import pizza from "../../images/deals/bogo/bogo-pizza.png";
import './deals.css';
import Locator from "../../components/locator/Locator";
//import {stores} from "../../data/stores";
import {getParticipatingStores} from "../../helpers/location"
import MyModal from "../../components/atoms/modal/myModal";
import {graphql} from "gatsby";
import {deals_footer_text} from "../../helpers/constants";
import Img from "gatsby-image"


function FiftyOffLargeXLarge({data}) {
    const title = '50% Off Large or X-Large';
    let stores = data.allContentfulStores;

    // console.log('data', data);

    const [isModalOpen, setIsModalOpen] = useState(false);

    function openModel() {
        setIsModalOpen(true);
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }

    let footerMessage = (
        <>
            <p>{deals_footer_text}</p>
        </>
    );

    let participatingStores = [112, 118, 123, 155];


    let myStores = getParticipatingStores(stores, participatingStores);

    return (
        <Layout useMain={true} hideMenu={true} customFooterMessage={footerMessage}>
            <SEO
                title={title}
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />
            <Page hideTitle={true} title={title} bgImage={wood} transparentPage={true}>


                <div className="flex flex-wrap">
                    <div className="w-full sm:w-full md:w-2/5 mb-4 md:pr-10 pb-10">

                        <div className="bg-black rounded-top">
                            <Img
                                fluid={data.imageSharp.fluid}
                                alt={"50% off Large or X-Large. When you buy 2 or more pizzas! Enter Code: 50PIZZaS in the coupon code box to redeem offer."}
                            />
                            {/*<img*/}
                            {/*    src={dealText}*/}
                            {/*    className="block mx-auto w-full"*/}
                            {/*    alt="Combination and Pepperoni Pizza"*/}
                            {/*/>*/}

                            <div className="bg-white order-now-div">
                            <span className="h2">
                                <a className={"text-red-800 font-bold sm:text-md md:text-3xl lg:text-4xl"} href={'#/'}
                                   onClick={openModel}>ORDER NOW ></a>
                            </span>
                            </div>

                            <div className="bg-black rounded-bottom">
                            </div>

                        </div>
                    </div>
                    <div className="w-full sm:w-full md:w-3/5 mb-4 md:pr-10 md:pb-10">
                        <Img
                            fluid={data.bgImage.fluid}
                            alt={"50% off Large or X-Large. When you buy 2 or more pizzas! Enter Code: 50PIZZaS in the coupon code box to redeem offer."}
                        />
                        {/*<img*/}
                        {/*    src={pizza}*/}
                        {/*    className="block mx-auto w-full"*/}
                        {/*    alt="Combination and Pepperoni Pizza"*/}
                        {/*/>*/}
                    </div>
                </div>

                <MyModal title={'Start Order'} isOpen={isModalOpen} toggleModal={toggleModal}>
                    <div className={'bg-gray-100'}>
                        <Locator
                            storesPerPage={4}
                            showDetails={false}
                            showPhone={false}
                            participatingStores={participatingStores}
                            stores={myStores}
                            allCompanyStores={stores.edges}
                        />
                    </div>
                </MyModal>


            </Page>
        </Layout>
    );
}

export default FiftyOffLargeXLarge;

export const query = graphql`
{
  allContentfulStores(filter: {node_locale: {eq: "en-US"}}) {
    edges {
      node {
        id
        contentful_id
        phoneNumber
        storeId
        visibleStoresMap
        specialUrl
        rating
        orderUrl
        menuUrl
        coordinates {
          lat
          lon
        }
        address {
          city
          country
          state
          streetName
          streetName2
          streetNumber
          streetSuffix
          zipCode
        }
      }
    }
  }
  
      imageSharp(fixed: {originalName: {eq: "50-off.png"}}) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      
      }
        bgImage:imageSharp(fixed: {originalName: {eq: "50-off-bg.png"}}) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      
      }
    

}

  `;
